import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
import HurbLogo from './hurb';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, isDarkTheme = false, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = isDarkTheme ? 'white' : theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = isDarkTheme ? 'rgba(255,255,255,.5)' : theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>

        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          />
        </g>
      </svg> */}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width='100%'
        height='100%'
        viewBox="0 0 512 512"
        xmlSpace="preserve"

      >
        <defs />
        <g transform="matrix(3.68 0 0 3.68 251.55 241.45)">
          <g style={{}}>
            <g transform="matrix(1 0 0 1 -0.23 -7.76)">
              <g style={{}}>
                <g transform="matrix(0.22 0 0 0.22 -0.41 -36.9)">
                  <linearGradient
                    id="SVGID_89"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(1 0 0 1 -40 -40)"
                    x1={12}
                    y1={68}
                    x2={68}
                    y2={12}
                  >
                    <stop
                      offset="0%"
                      stopColor={PRIMARY_DARK}
                    />
                    <stop
                      offset="100%"
                      stopColor={PRIMARY_LIGHT}
                    />
                  </linearGradient>
                  <circle
                    style={{
                      stroke: "rgb(0,0,0)",
                      strokeWidth: 0,
                      strokeDasharray: "none",
                      strokeLinecap: "butt",
                      strokeDashoffset: 0,
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 4,
                      fill: "url(#SVGID_89)",
                      fillRule: "nonzero",
                      opacity: 1,
                    }}
                    cx={0}
                    cy={0}
                    r={40}
                  />
                </g>
                <g transform="matrix(1.13 0 0 1 0 10.6)">
                  <linearGradient
                    id="SVGID_105"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(1 0 0 1 244.08 298.78)"
                    x1={0}
                    y1={35.10204081632651}
                    x2={42.04874107991418}
                    y2={35.10204081632651}
                  >
                    <stop
                      offset="0%"
                      stopColor={PRIMARY_DARK}
                    />
                    <stop
                      offset="100%"
                      stopColor={PRIMARY_LIGHT}
                    />
                  </linearGradient>
                  <path
                    style={{
                      stroke: "rgb(0,0,0)",
                      strokeWidth: 0,
                      strokeDasharray: "none",
                      strokeLinecap: "butt",
                      strokeDashoffset: 0,
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 4,
                      fill: "url(#SVGID_105)",
                      fillRule: "nonzero",
                      opacity: 1,
                    }}
                    vectorEffect="non-scaling-stroke"
                    transform=" translate(-265.11, -333.88)"
                    d="M 244.0816326530612 298.7755102040816 L 286.1303737329754 298.7755102040816 Q 268.9795918367347 321.63265306122446 264.0816326530612 368.97959183673464 Q 261.2244897959183 324.08163265306126 244.0816326530612 298.7755102040816"
                    strokeLinecap="round"
                  />
                </g>
              </g>
            </g>
            <g transform="matrix(0.83 0.56 -0.56 0.83 31.05 1.99)">
              <g style={{}} vectorEffect="non-scaling-stroke">
                <g transform="matrix(0.22 0 0 0.22 -0.41 -36.9)">
                  <linearGradient
                    id="SVGID_106"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(1 0 0 1 -40 -40)"
                    x1={12}
                    y1={68}
                    x2={68}
                    y2={12}
                  >
                    <stop
                      offset="0%"
                      stopColor={PRIMARY_DARK}
                    />
                    <stop
                      offset="100%"
                      stopColor={PRIMARY_LIGHT}
                    />
                  </linearGradient>
                  <circle
                    style={{
                      stroke: "rgb(0,0,0)",
                      strokeWidth: 0,
                      strokeDasharray: "none",
                      strokeLinecap: "butt",
                      strokeDashoffset: 0,
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 4,
                      fill: "url(#SVGID_106)",
                      fillRule: "nonzero",
                      opacity: 1,
                    }}
                    cx={0}
                    cy={0}
                    r={40}
                  />
                </g>
                <g transform="matrix(1.13 0 0 1 0.09 10.6)">
                  <linearGradient
                    id="SVGID_107"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(1 0 0 1 244.08 298.78)"
                    x1={0}
                    y1={35.10204081632651}
                    x2={42.04874107991418}
                    y2={35.10204081632651}
                  >
                    <stop
                      offset="0%"
                      stopColor={PRIMARY_DARK}
                    />
                    <stop
                      offset="100%"
                      stopColor={PRIMARY_LIGHT}
                    />
                  </linearGradient>
                  <path
                    style={{
                      stroke: "rgb(0,0,0)",
                      strokeWidth: 0,
                      strokeDasharray: "none",
                      strokeLinecap: "butt",
                      strokeDashoffset: 0,
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 4,
                      fill: "url(#SVGID_107)",
                      fillRule: "nonzero",
                      opacity: 1,
                    }}
                    transform=" translate(-265.11, -333.88)"
                    d="M 244.0816326530612 298.7755102040816 L 286.1303737329754 298.7755102040816 Q 268.9795918367347 321.63265306122446 264.0816326530612 368.97959183673464 Q 261.2244897959183 324.08163265306126 244.0816326530612 298.7755102040816"
                    strokeLinecap="round"
                  />
                </g>
              </g>
            </g>
            <g transform="matrix(0.81 -0.58 0.58 0.81 -30.49 2.5)">
              <g style={{}} vectorEffect="non-scaling-stroke">
                <g transform="matrix(0.22 0 0 0.22 -0.41 -36.9)">
                  <linearGradient
                    id="SVGID_108"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(1 0 0 1 -40 -40)"
                    x1={12}
                    y1={68}
                    x2={68}
                    y2={12}
                  >
                    <stop
                      offset="0%"
                      stopColor={PRIMARY_DARK}
                    />
                    <stop
                      offset="100%"
                      stopColor={PRIMARY_LIGHT}
                    />
                  </linearGradient>
                  <circle
                    style={{
                      stroke: "rgb(0,0,0)",
                      strokeWidth: 0,
                      strokeDasharray: "none",
                      strokeLinecap: "butt",
                      strokeDashoffset: 0,
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 4,
                      fill: "url(#SVGID_108)",
                      fillRule: "nonzero",
                      opacity: 1,
                    }}
                    cx={0}
                    cy={0}
                    r={40}
                  />
                </g>
                <g transform="matrix(1.13 0 0 1 0.09 10.6)">
                  <linearGradient
                    id="SVGID_109"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(1 0 0 1 244.08 298.78)"
                    x1={0}
                    y1={35.10204081632651}
                    x2={42.04874107991418}
                    y2={35.10204081632651}
                  >
                    <stop
                      offset="0%"
                      stopColor={PRIMARY_DARK}
                    />
                    <stop
                      offset="100%"
                      stopColor={PRIMARY_LIGHT}
                    />
                  </linearGradient>
                  <path
                    style={{
                      stroke: "rgb(0,0,0)",
                      strokeWidth: 0,
                      strokeDasharray: "none",
                      strokeLinecap: "butt",
                      strokeDashoffset: 0,
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 4,
                      fill: "url(#SVGID_109)",
                      fillRule: "nonzero",
                      opacity: 1,
                    }}
                    transform=" translate(-265.11, -333.88)"
                    d="M 244.0816326530612 298.7755102040816 L 286.1303737329754 298.7755102040816 Q 268.9795918367347 321.63265306122446 264.0816326530612 368.97959183673464 Q 261.2244897959183 324.08163265306126 244.0816326530612 298.7755102040816"
                    strokeLinecap="round"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
      {/* <Box
        ref={ref}
        component="div"
        sx={{
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
      >
        <HurbLogo fill={PRIMARY_LIGHT} />
      </Box> */}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
