import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Container, Stack, Typography, TextField, MenuItem, FormControlLabel, Checkbox, Zoom, Alert, CircularProgress, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers';
import { addDays, differenceInDays, formatISO } from 'date-fns';

import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import NovaSolicitacao from '../sections/@dashboard/ferias/NovaSolicitacao';
// components
import Iconify from '../components/iconify';
import ReqField from '../components/ReqField';


// mock
import { formatFluxos } from '../api/fluxos';
import { sendRequisicao } from '../api/requisicoes';
import { gerarContracheque } from '../api/contracheque';
import { gerarInformeRendimentos } from '../api/informeRendimentos';
import { getBase64 } from '../utils/getBase64';

// ----------------------------------------------------------------------
const relatoriosForm = [
  {
    nome: 'Contracheque',
    campos: [
      {
        key: 'mesComp',
        type: 'int',
        label: 'Mês',
        gridWidth: 2,
        required: true
      },
      {
        key: 'anoComp',
        type: 'int',
        label: 'Ano',
        gridWidth: 2,
        required: true
      }
    ]
  },
  {
    nome: 'Informe de rendimentos',
    campos: [
      {
        key: 'ano',
        type: 'int',
        label: 'Ano',
        gridWidth: 2,
        required: true
      }
    ]
  }
]

export default function RelatoriosPage() {
  const [relatorios, setRelatorios] = useState(relatoriosForm)
  const [relatorioSelecionado, setRelatorioSelecionado] = useState(relatorios?.[0]?.nome)
  const [relatorio, setRelatorio] = useState('')

  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [formValue, setFormValue] = useState({
    Contracheque: {
      mesComp: new Date().getMonth() + 1,
      anoComp: new Date().getFullYear()
    },
    "Informe de rendimentos": {
      ano: new Date().getFullYear() - 1
    }
  })
  const [minMax, setMinMax] = useState({
    mesComp: { min: 0, max: 12 },
    anoComp: { max: 2100 },
  })

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAuth()
  const { codCliente, codColigada, chapa } = user


  const handleChange = (e) => {
    console.log(e)
    const changes = {}

    if (e.target.type === 'checkbox') {
      changes[e.target.name] = e.target.checked
    } else if (e.target.type === 'file') {
      changes[e.target.name] = e.target.files[0]
    } else if (e.target.type === 'number') {
      console.log(e.target.value)
      changes[e.target.name] = Math.min(Math.max(e.target.min || 0, e.target.value), e.target.max || Infinity)
    } else changes[e.target.name] = e.target.value

    console.log({ ...formValue, [relatorioSelecionado]: { ...formValue?.[relatorioSelecionado], ...changes } })
    setFormValue(prev => ({ ...prev, [relatorioSelecionado]: { ...prev?.[relatorioSelecionado], ...changes } }))


  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const formData = Object.fromEntries(new FormData(event.currentTarget).entries());
    const req = relatorioSelecionado === 'Contracheque' ? {

      "codCliente": codCliente,
      "codColigada": codColigada,
      "chapa": chapa,
      "anoComp": formValue.Contracheque.anoComp,
      "mesComp": formValue.Contracheque.mesComp,
      "nroPeriodo": 2,
      "inline": true,
    } : {
      "codCliente": codCliente,
      "codColigada": codColigada,
      "chapa": chapa,
      "ano": formValue['Informe de rendimentos'].ano,
      "nroPeriodo": 2,
      "inline": true,
    }


    // manualOverride(req)

    console.log(req)


    setIsLoading(true)
    setAlertMessage("")

    try {
      const res = relatorioSelecionado === 'Contracheque' ?
        await gerarContracheque(req) :
        await gerarInformeRendimentos(req)


      if (res) {
        setRelatorio(res)
      }


    } catch (err) {
      console.error(err.message)
      if (err?.response?.status === 400 && err?.response?.data !== 'Parâmetros inválidos!') navigate('/login', { state: { from: location }, replace: true })
      setAlertMessage(err.message)
      window.scrollTo(0, 0)

    }
    setIsLoading(false)

  };

  const handleDownload = async () => {
    const req = relatorioSelecionado === 'Contracheque' ? {

      "codCliente": codCliente,
      "codColigada": codColigada,
      "chapa": chapa,
      "anoComp": formValue.Contracheque.anoComp,
      "mesComp": formValue.Contracheque.mesComp,
      "nroPeriodo": 2,
      "inline": true,
    } : {
      "codCliente": codCliente,
      "codColigada": codColigada,
      "chapa": chapa,
      "ano": formValue['Informe de rendimentos'].ano,
      "nroPeriodo": 2,
      "inline": true,
    }

    setIsDownloading(true)

    try {

      const res = relatorioSelecionado === 'Contracheque' ?
        await gerarContracheque(req, 'pdf') :
        await gerarInformeRendimentos(req, 'pdf')


      if (res) {
        const base64 = await getBase64(res)
        const a = document.createElement("a")
        a.href = base64
        a.download = relatorioSelecionado === 'Contracheque' ? "Contracheque.pdf" : "Informe de Rendimentos.pdf"
        a.click()
      }


    } catch (err) {
      if (err?.response?.status === 400 && err?.response?.data !== 'Parâmetros inválidos!') navigate('/login', { state: { from: location }, replace: true })
      console.error(err.message)
      setAlertMessage(err.message)
      window.scrollTo(0, 0)

    }
    setIsDownloading(false)

  }


  return (
    isPageLoading ?
      <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 168px)' }}>
        <CircularProgress />
      </Container>
      : !relatorios.length ?
        <Typography variant="h4" gutterBottom>
          Nenhum dado para ser exibido
        </Typography>
        : <>
          <Helmet>
            <title> Relatórios </title>
          </Helmet>



          <Container >
            <Stack gap={5} component="form" onSubmit={handleSubmit}>

              <Zoom direction="up" in={!!alertMessage} mountOnEnter unmountOnExit>
                <Alert variant='filled' severity={alertMessage === "Requisição enviada com sucesso!" || !alertMessage ? 'success' : 'error'}>{alertMessage}</Alert>
              </Zoom>

              <Stack direction="row" alignItems="center" gap={2}>
                <Typography variant="h4" gutterBottom>
                  Relatórios
                </Typography>
                <TextField select size="small" value={relatorioSelecionado} label='Relatório' name='relatorio' onChange={e => setRelatorioSelecionado(e.target.value)}>
                  {relatorios.map((relatorio) => (
                    <MenuItem key={relatorio.nome} value={relatorio.nome}>
                      {relatorio.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>

              <Grid container spacing={2}>
                {relatorios.filter(relatorio => relatorio.nome === relatorioSelecionado)[0].campos.map(campo => (
                  <Grid xs={12} md={campo.gridWidth} key={campo.key} display={campo.displayNone ? 'none' : 'unset'}>
                    <ReqField
                      type={campo.type}
                      minMax={minMax}
                      label={campo.label}
                      name={campo.key.toString()}
                      fullWidth
                      onChange={handleChange}
                      value={formValue[relatorioSelecionado]?.[campo.key.toString()] || ''}
                      disabled={campo.readOnly || false}
                      required={campo.required}
                    />
                  </Grid>
                ))}
              </Grid>

              <Stack direction={'row'} gap={2} maxWidth={500}>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
                  Visualizar
                </LoadingButton>
                <LoadingButton fullWidth size="large" variant="contained" loading={isDownloading} onClick={handleDownload}>
                  Baixar
                </LoadingButton>
              </Stack>

              {relatorio &&
                <Stack gap={5} alignItems={'center'}>
                  <Container dangerouslySetInnerHTML={{ __html: relatorio }} sx={{ display: { xs: 'block', md: 'contents' }, paddingLeft: 0, mt: { xs: '2em', md: 0 } }} />
                </Stack>
              }

            </Stack>
          </Container>
        </>
  );

  // return <NovaSolicitacao />
}
