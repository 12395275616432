import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import DadosCadastraisPage from './pages/DadosCadastraisPage';
import HistoricoFeriasPage from './pages/HistoricoFeriasPage';
import RequisicoesPage from './pages/RequisicoesPage';
import NovaRequisicaoPage from './pages/NovaRequisicaoPage';
import RelatoriosPage from './pages/RelatoriosPage';
import BlogPage from './pages/BlogPage';
import EquipePage from './pages/EquipePage';
import LoginPage from './pages/LoginPage';
import PerfilPage from './pages/PerfilPage';
import Page404 from './pages/Page404';
import Unauthorized from './pages/Unauthorized';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: <PersistLogin />, children: [

        {
          element: <RequireAuth />, children: [
            {
              path: '/app',
              element: <DashboardLayout />,
              children: [
                { element: <Navigate to="/app/dados-cadastrais" />, index: true },

                { path: 'relatorios', element: <RelatoriosPage /> },
                { path: 'dados-cadastrais', element: <DadosCadastraisPage /> },
                { path: 'requisicoes', element: <RequisicoesPage /> },
                { path: 'nova-requisicao', element: <NovaRequisicaoPage /> },
                { path: 'historico-ferias', element: <HistoricoFeriasPage /> },
                { path: 'perfil', element: <PerfilPage /> },

                {
                  element: <RequireAuth allowedRoles={['gestor']} />, children: [
                    { path: 'equipe', element: <EquipePage /> },
                    { path: 'products', element: <ProductsPage /> },
                    { path: 'blog', element: <BlogPage /> },
                  ]
                }
              ]
            },


          ]
        }


      ],

    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/app/dados-cadastrais" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: 'unauthorized', element: <Unauthorized /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
