// ----------------------------------------------------------------------

export default function Tab(theme) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          '&:hover': {
            // color: theme.palette.primary.dark,
            backgroundColor: theme.palette.grey[200],
            opacity: 1,
            borderRadius: '8px 0 0 8px'
          },
        },
      },
    },

  };
}
