import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { useNavigate, useLocation } from "react-router-dom";

// @mui
import { Grid, Container, Stack, Typography, TextField, MenuItem, Box, Card, CardContent, CircularProgress, styled } from '@mui/material';

// mock
import { formatDadosCadastrais } from '../api/dadosCadastrais';
import VerticalTabs from '../components/VerticalTabs';
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

// ----------------------------------------------------------------------

export default function DadosCadastraisPage() {

  const [matricula, setMatricula] = useState('')
  const [dadosCadastrais, setDadosCadastrais] = useState([])

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAuth()
  const { codCliente } = user

  const [isPageLoading, setIsPageLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getData = async () => {
      try {
        setIsPageLoading(true)
        const response = await axiosPrivate.get(`/DadosCadastrais/${codCliente}${location.search}`, {
          signal: controller.signal
        });
        setIsPageLoading(false)

        console.log(response.data);

        if (isMounted) {
          const data = formatDadosCadastrais(response.data)
          setDadosCadastrais(data);
          setMatricula(data[0].matricula)
        }

      } catch (err) {
        setIsPageLoading(false)

        console.error(err);
        if (err?.response?.status === 400
          && err?.response?.data !== 'Parâmetros inválidos!'
          && err?.response?.data !== 'Object reference not set to an instance of an object.')
          navigate('/login', { state: { from: location }, replace: true });
      }
    }

    getData();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [location.search])

  const CustomTextField = styled(TextField)({
    '& .Mui-disabled': {
      color: '#212B36 !important',
      WebkitTextFillColor: '#212B36 !important',
    },
  });

  return (
    isPageLoading ?
      <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 168px)' }}>
        <CircularProgress />
      </Container>
      : !dadosCadastrais?.length ?
        <Typography variant="h4" gutterBottom>
          Nenhum dado para ser exibido
        </Typography>
        : <>
          <Helmet>
            <title> Dados cadastrais </title>
          </Helmet>

          <Container>
            <Stack direction="row" alignItems="center" mb={5} gap={2}>
              <Typography variant="h4" gutterBottom>
                Dados cadastrais
              </Typography>
              <TextField select size="small" value={matricula} label='Matrícula' onChange={e => setMatricula(e.target.value)}>
                {dadosCadastrais.map((dados, i) => (
                  <MenuItem key={dados.matricula + i} value={dados.matricula}>
                    {dados.matricula}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>

            <VerticalTabs>
              {dadosCadastrais.filter(dado => dado.matricula === matricula)[0]?.secoes.map((secao) => (
                <Stack key={secao.titulo}>
                  <Typography variant='h5' mb={3}>{secao.titulo}</Typography>
                  {
                    secao.tipo === 'lista' ?
                      secao.lista.map((item, i) =>
                        <Box mb={3} key={secao.titulo + i}>
                          <Card variant="outlined" >
                            <CardContent sx={{ padding: { xs: '12px', md: '24px' } }}>
                              <Stack gap={2}>
                                {
                                  item.linhas.map((linha, j) =>
                                    <Stack direction={{ md: 'row' }} alignItems="center" gap={2} key={`${secao.titulo}${i}linha${j}`}>
                                      {linha.map(campo =>

                                        campo && <CustomTextField
                                          key={campo.titulo + campo.valor}
                                          label={campo.titulo}
                                          value={campo.valor}
                                          fullWidth
                                          disabled
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                          multiline
                                        />
                                      )}
                                    </Stack>
                                  )
                                }
                              </Stack>
                            </CardContent>
                          </Card>
                        </Box>
                      )
                      :
                      secao.linhas.map((linha, i) =>
                        <Stack direction={{ md: 'row' }} alignItems="center" mb={3} gap={2} key={secao.titulo + i}>
                          {linha.map(campo => campo &&
                            <CustomTextField
                              key={campo.titulo + campo.valor}
                              label={campo.titulo}
                              value={campo.valor}
                              fullWidth
                              disabled
                              InputProps={{
                                readOnly: true,
                              }}
                              multiline

                            />
                          )}
                        </Stack>
                      )
                  }
                </Stack>
              ))}
            </VerticalTabs>
          </Container>
        </>
  );
}
