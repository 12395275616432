import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { useNavigate, useLocation, Link } from "react-router-dom";
import { addYears, format } from 'date-fns';

// @mui
import { Grid, Container, Stack, Typography, TextField, MenuItem, Box, Card, CardContent, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Chip, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// mock
import { formatDadosCadastrais } from '../api/dadosCadastrais';
import VerticalTabs from '../components/VerticalTabs';
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

export default function HistoricoFeriasPage() {

  const [dados, setDados] = useState([])

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const mdUp = useResponsive('up', 'md')

  const { user } = useAuth()
  const { codCliente, codColigada, chapa } = user

  const [isPageLoading, setIsPageLoading] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getData = async () => {
      try {
        setIsPageLoading(true)
        const response = await axiosPrivate.get(`/DadosCadastrais/dados-historico-ferias/${codCliente}?codColigada=${codColigada}&chapa=${chapa}`, {

          signal: controller.signal
        });
        setIsPageLoading(false)

        console.log(response.data);

        if (isMounted) {
          setDados(response.data);
        }

      } catch (err) {
        setIsPageLoading(false)

        console.error(err);
        if (err?.response?.status === 400
          && err?.response?.data !== 'Parâmetros inválidos!'
          && err?.response?.data !== 'Object reference not set to an instance of an object.')
          navigate('/login', { state: { from: location }, replace: true });
      }
    }

    getData();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])


  return (
    isPageLoading ?
      <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 168px)' }}>
        <CircularProgress />
      </Container>
      : !dados?.length ?
        <Typography variant="h4" gutterBottom>
          Nenhum dado para ser exibido
        </Typography>
        : <>
          <Helmet>
            <title> Historico de férias </title>
          </Helmet>

          <Container>
            <Typography variant="h4">
              Historico de férias
            </Typography>
            <Typography variant="caption">
              Períodos aquisitivos
            </Typography>
            <div style={{ marginBottom: '1em' }} />


            {dados.map(periodo => (
              <Accordion
                key={periodo.inicioPerAquis}
                expanded={expanded === periodo.inicioPerAquis}
                onChange={handleChange(periodo.inicioPerAquis)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Stack direction={'row'} sx={{ justifyContent: 'space-between', width: '95%', alignItems: 'center' }}>
                    {mdUp ?
                      <Typography>{`${format(new Date(periodo.inicioPerAquis), 'dd/MM/yyyy')} - ${format(new Date(periodo.fimPerAquis), 'dd/MM/yyyy')}`}</Typography>
                      : <Typography>{`${format(new Date(periodo.inicioPerAquis), 'yyyy')} - ${format(new Date(periodo.fimPerAquis), 'yyyy')}`}</Typography>
                    }
                    {!!periodo.periodoAberto && !!periodo.saldo &&
                      <Stack direction={'row'} gap={2} sx={{ alignItems: 'center' }}>
                        <Typography variant='caption'>{`Saldo ${periodo.saldo} dias`}</Typography>
                        <Chip label='Aberto' color='success' size='small' />
                      </Stack>
                    }

                    {!periodo.periodoAberto && !!periodo.saldo &&
                      <Stack direction={'row'} gap={2} sx={{ alignItems: 'center' }}>
                        <Typography variant='caption'>{`Saldo ${periodo.saldo} dias`}</Typography>
                        <Chip label='Fechado' color='warning' size='small' />
                      </Stack>
                    }
                  </Stack>

                </AccordionSummary>

                <AccordionDetails>
                  {!!periodo.periodoAberto && !!periodo.saldo && <Button component={Link} to='/app/nova-requisicao#Férias' sx={{ mb: '2em' }}>Marcar férias</Button>}
                  {
                    !!periodo.histGozoFerias.length &&
                    (mdUp ?
                      <>
                        <TableContainer component={Paper} sx={{ width: { md: 'fit-content' } }} >
                          <Table sx={{ width: 'auto' }} size='small'>
                            <TableHead>
                              <TableRow>
                                <TableCell> </TableCell>
                                <TableCell align="right">Início</TableCell>
                                <TableCell align="right">Fim</TableCell>
                                <TableCell align="right">Dias</TableCell>
                                <TableCell align="right">Abono</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {periodo.histGozoFerias.reverse().map((parcela, i) => (


                                <TableRow
                                  key={parcela.dataInicio}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    <strong>{periodo.histGozoFerias.length === 1 ? 'Parcela única' : `${i + 1}ª parcela`}</strong>
                                  </TableCell>
                                  <TableCell align="right">{format(new Date(parcela.dataInicio), 'dd/MM/yyyy')}</TableCell>
                                  <TableCell align="right">{format(new Date(parcela.dataFim), 'dd/MM/yyyy')}</TableCell>
                                  <TableCell align="right">{parcela.nroDiasFerias}</TableCell>
                                  <TableCell align="right">{parcela.nroDiasAbono}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <Stack direction={'row'} mt={2} pl={2}>
                          <Typography variant='caption'>{`${periodo.solicitou13 ? 'R' : 'Não r'}ecebeu a primeira parcela do 13º salário nas férias`}</Typography>
                        </Stack>
                      </>
                      :
                      <>

                        {periodo.histGozoFerias.reverse().map((parcela, i) => (
                          <TableContainer component={Paper} sx={{ mb: '2em' }} key={parcela.dataInicio + i}>
                            <Table size='small'>
                              <TableHead>
                                <TableRow>
                                  <TableCell> </TableCell>
                                  <TableCell> {periodo.histGozoFerias.length === 1 ? 'Parcela única' : `${i + 1}ª parcela`}</TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <TableCell><strong>Início</strong></TableCell>
                                  <TableCell>{format(new Date(parcela.dataInicio), 'dd/MM/yyyy')}</TableCell>
                                </TableRow>

                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <TableCell><strong>Fim</strong></TableCell>
                                  <TableCell>{format(new Date(parcela.dataFim), 'dd/MM/yyyy')}</TableCell>
                                </TableRow>

                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <TableCell><strong>Dias</strong></TableCell>
                                  <TableCell>{parcela.nroDiasFerias}</TableCell>
                                </TableRow>

                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <TableCell><strong>Abono</strong></TableCell>
                                  <TableCell>{parcela.nroDiasAbono}</TableCell>
                                </TableRow>

                              </TableBody>
                            </Table>
                          </TableContainer>
                        ))}

                        <Stack direction={'row'} mt={2} pl={2}>
                          <Typography variant='caption'>{`${periodo.solicitou13 ? 'R' : 'Não r'}ecebeu a primeira parcela do 13º salário nas férias`}</Typography>
                        </Stack>
                      </>
                    )}


                </AccordionDetails>
              </Accordion>
            ))}


          </Container>
        </>
  );
}
