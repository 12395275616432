import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Container, Stack, Typography, Chip, CircularProgress, Card, CardHeader, CardContent, Stepper, Step, StepLabel, Slide, AccordionDetails, Accordion, AccordionSummary } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers';
import { addDays, addYears, differenceInDays, format } from 'date-fns';

import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useResponsive from '../hooks/useResponsive';

// mock
import { formatFluxos } from '../api/fluxos';
import { sendRequisicao } from '../api/requisicoes';

// ----------------------------------------------------------------------

function rename(fluxo) {
    const dict = {
        'Fluxo de requisições de Férias': 'Férias',
        'Fluxo de requisições de Alteração de Dados Cadastrais': 'Alteração de dados cadastrais'
    }

    return dict[fluxo] || fluxo
}

function color(status) {
    const dict = {
        'Em andamento': 'info',
        'Concluído': 'success',
        'Cancelado': 'warning',
        'REPROVADO': 'error',
        'APROVADO': 'success',
    }

    return dict[status] || 'default'
}

function Requisicoes({ reqs }) {
    const mdUp = useResponsive('up', 'md')

    return <Stack gap={2} sx={{ width: '100%' }}>
        {
            reqs.sort((a, b) => b.requisicaoCapa.dtRequisicao > a.requisicaoCapa.dtRequisicao ? 1 : -1).map(req => {
                const capa = req.requisicaoCapa
                const etapas = [
                    { "idEtapa": 0, "descricao": "Abertura" },
                    ...(req.etapasPossiveis || []),
                    { "idEtapa": 99, "descricao": "Conclusão" }
                ]
                return <Slide key={capa.fluxo + capa.numero} in timeout={500} direction='up'>
                    <Card  >
                        <CardHeader
                            title={rename(capa.fluxo)}
                            subheader={`${mdUp ? `Aberto em:` : ''} ${format(new Date(capa.dtRequisicao), 'dd/MM/yyyy HH:mm')}${mdUp ? '' : ` | ${capa.status}`}`}
                            action={
                                mdUp && <Chip label={capa.status} sx={{ ml: '1em', opacity: '85%' }}
                                    color={color(capa.status.trim())}
                                />
                            }
                        />
                        <CardContent>
                            {etapas.length &&
                                <Stepper activeStep={capa.finalizado ? 99 : capa.idEtapa || 0} orientation={mdUp ? 'horizontal' : 'vertical'}>
                                    {etapas.map(etapa => (
                                        <Step key={etapa.idEtapa}>
                                            <StepLabel>
                                                {etapa.descricao}
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            }


                        </CardContent>
                    </Card>
                </Slide >
            })
        }
    </Stack>


}

export default function MinhasRequisicoes() {



    const [data, setData] = useState([]);

    const [isPageLoading, setIsPageLoading] = useState(false);

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const { user } = useAuth()
    const { codCliente, codUsuario } = user

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getData = async () => {
            try {
                setIsPageLoading(true)
                const response = await axiosPrivate.get(`/Requisicoes/minhas-requisicoes/${codCliente}?codRequisitante=${codUsuario}`, {
                    signal: controller.signal
                });

                console.log('minhas reqs', response.data)

                if (isMounted) {
                    setData(response.data)
                }

                setIsPageLoading(false)


            } catch (err) {

                setIsPageLoading(false)

                if (err?.response?.status === 400 && err?.response?.data !== 'Parâmetros inválidos!')
                    navigate('/login', { state: { from: location }, replace: true });

                console.error(err)
            }
        }

        getData();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    return (
        isPageLoading ?
            <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <CircularProgress />
            </Container>

            :


            <Stack gap={2} sx={{ width: '100%' }}>
                {!data?.minhasReqsEmAndamento?.length ?
                    <Typography gutterBottom>
                        Nenhuma requisição em andamento
                    </Typography>
                    :
                    <Requisicoes reqs={data.minhasReqsEmAndamento} />
                }

                {!!data?.minhasReqsFinalizadas?.length && <div>
                    <Accordion sx={{ backgroundColor: 'transparent !important' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Histórico
                        </AccordionSummary>

                        <AccordionDetails>
                            <Requisicoes reqs={data.minhasReqsFinalizadas} />
                        </AccordionDetails>
                    </Accordion>
                </div>}

            </Stack >

    );

}
