// component
import HomeRounded from '@mui/icons-material/HomeRounded';
import PersonRounded from '@mui/icons-material/PersonRounded';
import PeopleRounded from '@mui/icons-material/PeopleRounded';
import SendRounded from '@mui/icons-material/SendRounded';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  // {
  //   title: 'Home',
  //   path: '/app/home',
  //   icon: <HomeRounded />,
  // },
  {
    title: 'Dados cadastrais',
    path: '/app/dados-cadastrais',
    icon: <PersonRounded />,
  },
  {
    title: 'Requisições',
    path: '/app/requisicoes',
    icon: <SendRounded />,
  },
  {
    title: 'Relatórios',
    path: '/app/relatorios',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Equipe',
    path: '/app/equipe',
    icon: <PeopleRounded />,
    roles: ['gestor']
  },
  // {
  //   title: 'product',
  //   path: '/app/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/app/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
