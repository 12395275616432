import { axiosPrivate } from './axios'

export async function gerarContracheque(data, formato = 'html') {

    try {
        const res = await axiosPrivate.post('/ContraCheque/gerar-contra-cheque', { ...data, formato },
            { withCredentials: true, responseType: formato === 'pdf' ? 'blob' : undefined })
        if (res.status === 200) {
            console.log(res)
            return res.data
        }
    } catch (err) {

        const erro = err.response?.data
        console.log(erro)

        if (erro === undefined) {
            throw new Error('Falha ao se conectar com o servidor')
        }

        throw new Error(JSON.stringify(erro))
        // return false
    }

    return false


}
