import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, Link as RouterLink } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Container, Stack, Typography, TextField, MenuItem, FormControlLabel, Checkbox, Zoom, Alert, CircularProgress, Button, Breadcrumbs, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers';
import { addDays, addYears, differenceInDays, format, formatISO, max, min } from 'date-fns';
import merge from 'lodash/merge';

import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useResponsive from '../hooks/useResponsive';
import NovaSolicitacao from '../sections/@dashboard/ferias/NovaSolicitacao';
// components
import Iconify from '../components/iconify';
import ReqField from '../components/ReqField';
import Modal from '../components/Modal'

// mock
import { formatFluxos } from '../api/fluxos';
import { sendRequisicao } from '../api/requisicoes';
import HistoricoFeriasPage from './HistoricoFeriasPage';
import { getBase64 } from '../utils/getBase64';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

async function formatValue(value, type) {
  switch (type) {
    case "int":
      return { valor: value === true ? '1' : value === undefined ? '' : value.toString() }

    case "varbinary":
      try {
        const base64 = await getBase64(value)

        return {
          valor: base64.split('base64,')[1],
          arquivoMimeType: value.type,
          arquivoNome: value.name,
        }
      } catch (err) {
        return { valor: '' }
      }

    case "datetime":
      return { valor: new Date(value).toJSON() }

    case "bit":
      return { valor: value === true ? '1' : '0' }

    default:
      return { valor: value?.toString() || '' }
  }
}

export default function RequisicoesPage() {
  const { user } = useAuth()
  const { codCliente, codColigada, codUsuario, chapa, roles } = user

  const mdUp = useResponsive('up', 'md')

  const [fluxoNome, setFluxoNome] = useState('')
  const [fluxos, setFluxos] = useState([])
  const [overrides, setOverrides] = useState({})

  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [formValue, setFormValue] = useState({})
  const [equipe, setEquipe] = useState([])
  const [pessoaAtendida, setPessoaAtendida] = useState('')
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [semSaldo, setSemSaldo] = useState(false)

  const [feriasParams, setFeriasParams] = useState({})

  const [minMax, setMinMax] = useState({})
  const [hasError, setHasError] = useState({})
  const [helperText, setHelperText] = useState({})

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const fileRef = useRef()


  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getData = async () => {
      try {
        setIsPageLoading(true)
        const response = await axiosPrivate.get(`/Fluxos/${codCliente}`, {
          signal: controller.signal
        });

        console.log(response.data);

        if (isMounted) {
          const data = formatFluxos(response.data)
          setFluxos(data);

          const hash = decodeURIComponent(location.hash.slice(1))
          // const query = new URLSearchParams(location.search)

          // if (query.get('codColigada') && query.get('chapa')) {
          //   setPessoaAtendida(query.get('codColigada') + ';' + query.get('chapa'))
          //   console.log(query.get('codColigada') + ';' + query.get('chapa'))
          // }
          setFluxoNome(hash || data[0].nome)
          // navigate('#' + (hash || data[0].nome))
        }

        setIsPageLoading(false)


      } catch (err) {
        setIsPageLoading(false)
        if (err?.response?.status === 400 && err?.response?.data !== 'Parâmetros inválidos!') navigate('/login', { state: { from: location }, replace: true });
      }
    }

    getData();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    setAlertMessage('')
    setSemSaldo(false)

    switch (fluxoNome) {
      case 'Férias':

        const getData = async () => {
          try {
            setIsPageLoading(true)


            if (roles.includes('gestor')) {
              const equipe = await axiosPrivate.get(`/Equipes/${codCliente}?codColigada=${codColigada}&chapa=${chapa}`, {
                signal: controller.signal
              });

              console.log('equipes', equipe)
              setEquipe([{ codColigada, chapa, nome: user.usuario },
              ...equipe.data.filter(pessoa => pessoa.codSituacao === 'A' && pessoa.funcao !== "PRESTADOR DE SERVICO")
                .sort((a, b) => a.nome > b.nome ? 1 : -1)
              ])


            }

            // Se houver parametros na URL, usa ele
            const query = new URLSearchParams(location.search)

            if (query.get('codColigada') && query.get('chapa')) {
              setPessoaAtendida(query.get('codColigada') + ';' + query.get('chapa'))
            } else {
              // setPessoaAtendida(codColigada + ';' + chapa)
              navigate(`/app/nova-requisicao?codColigada=${codColigada}&chapa=${chapa}#Férias`, { replace: true })
            }


            // if (isMounted) {

            // }

            setIsPageLoading(false)


          } catch (err) {
            setIsPageLoading(false)
            if (err?.response?.status === 400 && err?.response?.data !== 'Parâmetros inválidos!') navigate('/login', { state: { from: location }, replace: true });
          }
        }

        getData()

        break
      default:
    }

    return () => {
      isMounted = false;
      controller.abort();
    }

  }, [fluxoNome])

  useEffect(() => {
    const hash = decodeURIComponent(location.hash.slice(1))
    // if (!hash) navigate(0)
    if (hash && fluxoNome !== hash)
      setFluxoNome(hash)

    const query = new URLSearchParams(location.search)
    const pAtend = query.get('codColigada') + ';' + query.get('chapa')
    if (pAtend !== pessoaAtendida && query.get('codColigada'))
      setPessoaAtendida(pAtend)

  }, [location])

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    setAlertMessage('')

    switch (fluxoNome) {
      case 'Férias':
        const getData = async () => {
          try {
            setIsPageLoading(true)

            const [pColigada, pChapa] = pessoaAtendida.split(';')

            const response = await axiosPrivate.get(`/DadosCadastrais/dados-abertura-ferias/${codCliente}?codColigada=${pColigada}&chapa=${pChapa}`, {
              signal: controller.signal
            });

            if (isMounted) {
              const feriasParams = response.data

              console.log('férias', feriasParams)

              setSemSaldo(feriasParams.saldo === 0)



              setOverrides(prev => merge({}, prev, {
                [fluxoNome]: {
                  '8': { readOnly: !!feriasParams.solicitou13 },
                  '7': { readOnly: feriasParams.saldoAbono === 0 },
                }
              }))

              setFormValue(prev => ({
                ...prev, [fluxoNome]: {
                  ...prev?.[fluxoNome],
                  '3': formatISO(new Date(feriasParams.fimPerAquis), { representation: 'date' }),
                  '8': !!feriasParams.solicitou13
                }
              }))

              setMinMax({
                '4': {
                  min: formatISO(max([new Date(), addYears(new Date(feriasParams.inicioPerAquis), 1)]), { representation: 'date' }),
                  max: formatISO(addDays(addYears(new Date(feriasParams.fimPerAquis), 1), -4), { representation: 'date' })
                },
                '5': {
                  min: formatISO(addDays(new Date(), 5), { representation: 'date' }),
                  max: formatISO(addYears(new Date(feriasParams.fimPerAquis), 1), { representation: 'date' })
                },
                '6': {
                  min: 5,
                  max: feriasParams.saldo
                },
                '7': {
                  min: 0,
                  max: feriasParams.saldoAbono
                }
              })

              setHelperText(prev => ({
                ...prev, 'Férias': {
                  '4': `Início do período concessivo ${format(addYears(new Date(feriasParams.inicioPerAquis), 1), 'dd/MM/yyyy')}`,
                  '5': `Data limite ${format(addYears(new Date(feriasParams.fimPerAquis), 1), 'dd/MM/yyyy')}`,
                  '6': `O saldo é de ${feriasParams.saldo} dias`,
                  '7': `O saldo é de ${feriasParams.saldoAbono} dias`
                }
              }))

              setFeriasParams(feriasParams)
            }

            setIsPageLoading(false)


          } catch (err) {
            setIsPageLoading(false)
            console.error(err)
            if (err?.response?.status === 400 && err?.response?.data !== 'Parâmetros inválidos!') navigate('/login', { state: { from: location }, replace: true });
          }
        }

        getData()

        break
      default:
    }

    return () => {
      isMounted = false;
      controller.abort();
    }

  }, [pessoaAtendida])

  // Validation
  useEffect(() => {
    if (fluxoNome === 'Férias') {
      const isInvalid = {
        '4': formValue[fluxoNome]['4'] > minMax['4'].max || formValue[fluxoNome]['4'] < minMax['4'].min,
        '5': formValue[fluxoNome]['5'] > minMax['5'].max || formValue[fluxoNome]['5'] < minMax['5'].min,
        '6': formValue[fluxoNome]['6'] > feriasParams.saldo,
        '7': formValue[fluxoNome]['7'] > feriasParams.saldoAbono
      }

      // Saldo - férias - abono
      const saldoRemanescente = feriasParams.saldo - (formValue[fluxoNome]['6'] || 0) - (formValue[fluxoNome]['7'] || 0)

      if (saldoRemanescente < 0) {
        isInvalid['6'] = true
        isInvalid['7'] = true
      }

      let validationHelper = ''

      // Obrigar ser maior que 15 dias
      if (!feriasParams.solicitouMaior15Dias && saldoRemanescente < 15 && formValue[fluxoNome]['6'] < 15) {
        isInvalid['6'] = true
        if (feriasParams.saldo === 20) {
          validationHelper = `Precisa ser 15 ou 5 dias`
        } else if (feriasParams.saldo < 20) {
          validationHelper = `Você deve gozar todo o saldo de ${feriasParams.saldo - (formValue[fluxoNome]['7'] || 0)} dias`
        }
        else validationHelper = `Precisa ser maior ou igual a 15 ${feriasParams.saldo - 15 > 5 ? `ou menor que ${feriasParams.saldo - 15}` : ''}dias`
      }

      // Impede saldo remanescente < 5 dias
      if (saldoRemanescente < 5 && saldoRemanescente > 0) {
        isInvalid['6'] = true
        validationHelper = `Saldo remanescente não pode ser menor que 5 dias`
      }

      // Obriga saldo remanescente se última parcela
      if (feriasParams.qtdParcelasGozo === 2 && saldoRemanescente !== 0) {
        isInvalid['6'] = true
        validationHelper = `Você deve gozar todo o saldo de ${feriasParams.saldo - (formValue[fluxoNome]['7'] || 0)} dias`
      }

      setHelperText(prev => ({
        ...prev, 'Férias': {
          ...prev['Férias'],
          '6': validationHelper || `O saldo é de ${feriasParams.saldo} dias`,
        }
      }))

      setHasError(prev => ({
        ...prev, [fluxoNome]: isInvalid
      }))

      setDisableSubmit(Object.values(isInvalid).includes(true))
    }
  }, [formValue])

  const handleChange = (e) => {
    console.log(e)
    const changes = {}

    if (e.target.type === 'checkbox') {
      changes[e.target.name] = e.target.checked
    } else if (e.target.type === 'file') {
      changes[e.target.name] = e.target.files[0]
    } else changes[e.target.name] = e.target.value

    if (fluxoNome === 'Férias') {

      switch (e.target.name) {
        case '4':
          setMinMax(prev => ({
            ...prev, '5': {
              ...prev?.['5'],
              min: formatISO(addDays(new Date(e.target.value), 5), { representation: 'date' }),
              max: formatISO(min([
                addDays(new Date(e.target.value), feriasParams.saldo),
                addYears(new Date(feriasParams.fimPerAquis), 1)
              ])
                , { representation: 'date' }),
            }
          }))

          if (formValue[fluxoNome]['5'])
            changes['6'] = differenceInDays(new Date(formValue[fluxoNome]['5']), new Date(e.target.value)) + 1
          else if (formValue[fluxoNome]['6'])
            changes['5'] = formatISO(addDays(new Date(e.target.value), formValue[fluxoNome]['6']), { representation: 'date' })
          break
        case '5':
          if (formValue[fluxoNome]['4'])
            changes['6'] = differenceInDays(new Date(e.target.value), new Date(formValue[fluxoNome]['4'])) + 1
          else if (formValue[fluxoNome]['6'])
            changes['4'] = formatISO(addDays(new Date(e.target.value), formValue[fluxoNome]['6']), { representation: 'date' })
          break
        case '6':
          if (formValue[fluxoNome]['4'])
            changes['5'] = formatISO(addDays(new Date(formValue[fluxoNome]['4']), e.target.value), { representation: 'date' })
          else if (formValue[fluxoNome]['5'])
            changes['4'] = formatISO(addDays(new Date(formValue[fluxoNome]['5']), -parseFloat(e.target.value) + 2), { representation: 'date' })
          break
        default:
      }

    }

    console.log({ ...formValue, [fluxoNome]: { ...formValue?.[fluxoNome], ...changes } })
    setFormValue(prev => ({ ...prev, [fluxoNome]: { ...prev?.[fluxoNome], ...changes } }))



    // setFormValue(prev => ({ ...prev, [e.target.name]: e.target.value }))

  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const formData = Object.fromEntries(new FormData(event.currentTarget).entries());
    const req = fluxos.find(fluxo => fluxo.nome === fluxoNome).req
    console.log(req.dados)
    req.dados = await Promise.all(req.dados.map(async c => ({
      ...c,
      ...await formatValue(formValue[fluxoNome]?.[c.idDado], c.valor)
    })))
    req.capa.codRequisitante = codUsuario
    req.capa.dtRequisicao = new Date()

    // Manual override
    switch (req.capa.idFluxo) {
      case 1: // Férias
        const [pColigada, pChapa] = pessoaAtendida.split(';')
        req.dados[0].valor = pColigada
        req.dados[1].valor = pChapa
        break
      case 2: // Dados cadastrais
        req.dados[0].valor = codColigada.toString()
        req.dados[1].valor = chapa
        break
      default:
    }

    console.log(req)


    setIsLoading(true)
    setAlertMessage("")

    try {
      const res = await sendRequisicao(req)


      if (res) {
        console.log(res)
        setFormValue(prev => ({ ...prev, [fluxoNome]: {} }))
        fileRef.current && (fileRef.current.value = '')
        setAlertMessage("Requisição enviada com sucesso!")
      }


    } catch (err) {
      console.error(err.message)
      setAlertMessage(err.message)

    }
    setIsLoading(false)
    window.scrollTo(0, 0)

  };

  const handlePessoaAtendidaChange = e => {
    const [pColigada, pChapa] = e.target.value.split(';')

    if (pessoaAtendida !== e.target.value)
      navigate(`/app/nova-requisicao?codColigada=${pColigada}&chapa=${pChapa}#Férias`)
  }

  return (
    isPageLoading ?
      <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 168px)' }}>
        <CircularProgress />
      </Container>
      : !fluxos.length ?
        <Typography variant="h4" gutterBottom>
          Nenhum dado para ser exibido
        </Typography>
        : <>
          <Helmet>
            <title> Nova requisição </title>
          </Helmet>
          {
            // <Breadcrumbs aria-label="breadcrumb" sx={{ mt: -4, mb: 2, ml: 2 }}>
            //   <Link component={RouterLink} underline="hover" color="inherit" to="/app/requisicoes">
            //     Requisições
            //   </Link>
            //   <Typography color="text.primary">Nova requisição</Typography>
            // </Breadcrumbs>
          }


          <Container >


            <Stack gap={5} component="form" onSubmit={handleSubmit}>

              <Zoom direction="up" in={!!alertMessage} mountOnEnter unmountOnExit>
                <Alert variant='filled' severity={alertMessage === "Requisição enviada com sucesso!" || !alertMessage ? 'success' : 'error'}>{alertMessage}</Alert>
              </Zoom>

              <Stack direction="row" alignItems="center" gap={2}>
                <Typography variant="h4" gutterBottom>
                  Nova requisição
                </Typography>
                <TextField select size="small" value={fluxoNome} label='Fluxo' name='fluxo' onChange={e => navigate('#' + e.target.value)}>
                  {fluxos.map((fluxo) => (
                    <MenuItem key={fluxo.nome} value={fluxo.nome}>
                      {fluxo.nome}
                    </MenuItem>
                  ))}
                </TextField>
                {fluxoNome === 'Férias' && (true ?
                  <Button component={RouterLink} to='/app/historico-ferias'>Histórico</Button>

                  : <Modal buttonText={'Histórico'}>
                    <HistoricoFeriasPage />
                  </Modal>
                )}
              </Stack>

              <Grid container spacing={2}>

                {roles.includes('gestor') && fluxoNome === 'Férias' &&
                  <Grid xs={12} md={12}>
                    <TextField fullWidth select value={pessoaAtendida} label='Pessoa atendida' name='pessoaAtendida' onChange={handlePessoaAtendidaChange}>
                      {equipe.map((pessoa) => (
                        <MenuItem key={pessoa.chapa} value={pessoa.codColigada + ';' + pessoa.chapa}>
                          {pessoa.nome}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>}

                {semSaldo ?
                  <Grid xs={12} sx={{ mt: 2 }}>
                    <Typography variant='h4' textAlign={'center'}>Não há saldo disponível para marcação</Typography>
                  </Grid>
                  : fluxos.find(fluxo => fluxo.nome === fluxoNome).campos.map(c => {
                    const campo = merge({}, c, overrides?.[fluxoNome]?.[c.key.toString()])
                    return <Grid xs={12} md={campo.gridWidth} key={campo.key} display={campo.displayNone ? 'none' : 'unset'}>
                      <ReqField
                        type={campo.type}
                        minMax={minMax}
                        options={campo.options}
                        label={campo.label}
                        name={campo.key.toString()}
                        fullWidth
                        onChange={handleChange}
                        value={formValue[fluxoNome]?.[campo.key.toString()] || ''}
                        disabled={campo.readOnly || false}
                        error={hasError[fluxoNome]?.[campo.key.toString()]}
                        helperText={helperText[fluxoNome]?.[campo.key.toString()]}
                        required={campo.required || false}
                        ref={fileRef}
                      />
                    </Grid>
                  }
                  )}
              </Grid>


              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading} disabled={disableSubmit || semSaldo}>
                Enviar
              </LoadingButton>

            </Stack>
          </Container>
        </>
  );

  // return <NovaSolicitacao />
}
