import { createContext, useState, useEffect } from "react";
import PropTypes from 'prop-types';

import config from '../config.json'

const AuthContext = createContext({});


AuthProvider.propTypes = {
    children: PropTypes.node
}

export function AuthProvider({ children }) {
    const [auth, setAuth] = useState(config.MOCK ? { accessToken: '123' } : JSON.parse(localStorage.getItem("auth")) || {});
    const [persist, setPersist] = useState(JSON.parse(localStorage.getItem("persist")) || false);

    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")) || {});


    useEffect(() => {
        persist && window.localStorage.setItem('auth', JSON.stringify({ usuariosApp: auth.usuariosApp }))
    }, [auth, persist]);

    useEffect(() => {
        persist && window.localStorage.setItem('user', JSON.stringify(user))
    }, [user, persist]);

    return (
        <AuthContext.Provider value={{ auth, setAuth, persist, setPersist, user, setUser }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;