import api from './axios'
import config from '../config.json'


async function loginMock({ cpf, password }) {
    await new Promise((resolve) => setTimeout(resolve, 1500))

    // if (email === "a@b.com" && password === '123') return true
    // return false

    return {
        "accessToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2NzMxNDAxODYsImV4cCI6MTY3MzE0MDQ4NiwiaWF0IjoxNjczMTQwMTg2LCJpc3MiOiJQb3B1bG9SSCIsImF1ZCI6Imh0dHBzOi8vd3d3LnBvcHVsb3JoLmNvbS5iciJ9.cWOVFgHgKDiiQWkV5E1193KsJozPp_KN9JoMLNh3tfU",
        "expiresIn": 300,
        "refreshToken": "Dys2fYbt0fKRA7PUw6nkrLwQwYF5gf4yvFE/+mkzCx805aoLm6rOhS1BS6b3Z0yJIlbU+OLe3wu1eGBK4XCkrw==",
        "expRefreshToken": "2023-01-08T01:39:46.5233093Z",
        "userToken": {
            "cpf": "03067247744",
            "claims": []
        },
        "usuariosApp": [
            {
                "codUsuario": 947,
                "codCliente": 2,
                "cliente": "SBM",
                "ativo": 1,
                "dtInicio": "2023-01-07T00:00:00",
                "dtFinal": null,
                "alteraSenha": 1,
                "idAspNetUser": "090e1bd1-8ac0-40d8-b1fc-44dd385c5fe0"
            }
        ]
    }
}

function throwErr(err) {
    throw new Error(err)
}

async function loginApi({ cpf, password }) {

    try {
        const res = await api.post('/login', { cpf, password }, { withCredentials: true })
        if (res.status === 200) {
            console.log(res.data)
            return res.data
        }
    } catch (err) {

        const erro = err.response?.data
        console.log(erro)

        if (erro === undefined) {
            throwErr('Falha ao se conectar com o servidor')
        }

        if (typeof erro === 'string')
            throwErr(erro)
        else if (erro.Password)
            throwErr(erro.Password[0])
        else throwErr(JSON.stringify(erro))
        // return false
    }

    return false


}


export const login = config.MOCK ? loginMock : loginApi

export async function logout(cpf) {
    try {
        const res = await api.post(`/logout/${cpf}`, { withCredentials: true })
        if (res.status === 200)
            return true
    } catch (err) {
        console.error(err)
        return false
    }

    return false
}

export async function trocarSenha(dados) {
    try {
        console.log(dados)
        const res = await api.put(`/change-password`, dados, { withCredentials: true })
        if (res.status === 200)
            return true
    } catch (err) {
        const erro = err.response?.data
        console.error(err)

        if (erro === undefined) {
            throwErr('Falha ao se conectar com o servidor')
        }

        if (typeof erro === 'string')
            throwErr(erro)
        else if (erro.Password)
            throwErr(erro.Password[0])
        else throwErr(JSON.stringify(erro))
    }

    return false
}