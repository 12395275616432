import { useLocation, Navigate, Outlet } from "react-router-dom";
import PropTypes from 'prop-types';
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth, user } = useAuth();
    const location = useLocation();

    return (

        !auth?.accessToken
            ? <Navigate to="/login" state={{ from: location }} replace />
            : !allowedRoles || user?.roles.find(role => allowedRoles?.includes(role))
                ? <Outlet />
                : <Navigate to="/unauthorized" state={{ from: location }} replace />
    );
}

RequireAuth.propTypes = {
    allowedRoles: PropTypes.array
}

export default RequireAuth;