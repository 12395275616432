import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Container, Stack, Typography, Chip, CircularProgress, Card, CardHeader, CardContent, Stepper, Step, StepLabel, Slide, Accordion, AccordionDetails, AccordionSummary, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers';
import { addDays, addYears, differenceInDays, format } from 'date-fns';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useResponsive from '../hooks/useResponsive';

// mock
import { formatFluxos } from '../api/fluxos';
import { sendRequisicao } from '../api/requisicoes';

// ----------------------------------------------------------------------

function rename(fluxo) {
    const dict = {
        'Fluxo de requisições de Férias': 'Férias',
        'Fluxo de requisições de Alteração de Dados Cadastrais': 'Alteração de dados cadastrais'
    }

    return dict[fluxo] || fluxo
}

function color(status) {
    const dict = {
        'Em andamento': 'info',
        'Concluído': 'success',
        'Cancelado': 'warning'
    }

    return dict[status] || 'default'
}

const etapas = {
    1: [
        { "idEtapa": 0, "descricao": "Abertura" },
        { "idEtapa": 99, "descricao": "Conclusão" }
    ],
    2: [
        { "idEtapa": 0, "descricao": "Abertura" },
        { "idEtapa": 1, "descricao": "Aprovação do lider imediato" },
        { "idEtapa": 99, "descricao": "Conclusão" }
    ]
}


export default function RequisicoesParaAprovacao() {

    const mdUp = useResponsive('up', 'md')

    const [data, setData] = useState({});

    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sucesso, setSucesso] = useState({}); // aberto, sucesso, erro
    const [out, setOut] = useState({}); // aberto, sucesso, erro
    const [error, setError] = useState({});

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const { user } = useAuth()
    const { codCliente, codUsuario } = user

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getData = async () => {
            try {
                setIsPageLoading(true)
                const response = await axiosPrivate.get(`/Requisicoes/requisicoes-para-aprovacao/${codCliente}?codResponsavel=${codUsuario}`, {
                    signal: controller.signal
                });

                console.log(response.data);

                if (isMounted) {
                    setData(response.data)
                }

                setIsPageLoading(false)


            } catch (err) {

                setIsPageLoading(false)

                if (err?.response?.status === 400 && err?.response?.data !== 'Parâmetros inválidos!')
                    navigate('/login', { state: { from: location }, replace: true });

                console.error(err)
            }
        }

        getData();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [])

    const handleResposta = (resposta) => async (e) => {
        const id = e.target.offsetParent.id
        const capa = data.reqsParaAprovacao[id].requisicaoCapa

        try {
            setIsPageLoading(true)
            const response = await axiosPrivate.post(`/Requisicoes/inserir-resposta-etapa`, {
                "codCliente": capa.codCliente,
                "idFluxo": capa.idFluxo,
                "numero": capa.numero,
                "idEtapa": capa.idEtapa,
                "ordem": capa.ordem,
                "codResponsavel": capa.codResponsavel,
                "resposta": resposta.trim(),
                "dataHora": new Date()
            });
            setIsPageLoading(false)

            setSucesso(prev => ({ ...prev, [id]: resposta }))
            setTimeout(() => {
                setOut(prev => ({ ...prev, [id]: true }))
                setData(prev => ({
                    reqsParaAprovacao: prev.reqsParaAprovacao.filter(req => req.id !== id),
                    reqsRespondidas: [...prev.reqsRespondidas, {
                        ...data.reqsParaAprovacao[id],
                        requisicaoCapa: {
                            ...data.reqsParaAprovacao[id].requisicaoCapa,
                            status: resposta.trim()
                        }
                    }]
                }))
            }, 3000)



        } catch (err) {

            setIsPageLoading(false)

            if (err?.response?.status === 400 && err?.response?.data !== 'Parâmetros inválidos!')
                navigate('/login', { state: { from: location }, replace: true });

            else setError(prev => ({ ...prev, [id]: JSON.stringify(err.message) }))

            console.error(err)
        }
    }


    return (
        isPageLoading ?
            <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <CircularProgress />
            </Container>

            :

            <Stack gap={2} sx={{ width: '100%' }}>
                {!data?.reqsParaAprovacao?.length ?
                    <Typography gutterBottom>
                        Nenhuma requisição para ser aprovada
                    </Typography>

                    : data.reqsParaAprovacao.sort((a, b) => b.requisicaoCapa.dtRequisicao < a.requisicaoCapa.dtRequisicao ? 1 : -1).map((req, i) => {
                        const capa = req.requisicaoCapa
                        return (
                            <Slide key={capa.fluxo + capa.numero} in={!out[i] || !sucesso[i]} timeout={500} direction='up' unmountOnExit>

                                <Card id={i} sx={{ bgcolor: error[i] ? '#ffb5b5' : sucesso[i] && '#7010a52e' }} >

                                    <CardContent sx={{ pt: '12px' }}>


                                        <Accordion sx={{ mb: '1em', bgcolor: 'transparent' }}  >
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 0, m: 0, ':first-of-type': { m: 0 } }} >
                                                <CardHeader
                                                    title={rename(capa.fluxo)}
                                                    subheader={<Typography variant='body2' color={'GrayText'} sx={{ whiteSpace: 'pre-line' }}>{`${mdUp ? `Aberto por` : ''} ${capa.requisitante} ${mdUp ? 'em' : '\n'} ${format(new Date(capa.dtRequisicao), 'dd/MM/yyyy HH:mm')}`}</Typography>}
                                                    sx={{ p: 0 }}
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Stack gap={1}>

                                                    {req.dados.map(campo => (
                                                        <TextField key={campo.descricao} label={campo.descricao} value={campo.valor} size='small' InputProps={{
                                                            readOnly: true,
                                                        }} />
                                                    ))}
                                                </Stack>
                                            </AccordionDetails>
                                        </Accordion>

                                        {error[i] ?
                                            <Typography gutterBottom>
                                                Erro ao submeter resposta: {error[i]}
                                            </Typography>
                                            :
                                            sucesso[i] ?
                                                <Typography gutterBottom align='center'>
                                                    {sucesso[i]}
                                                </Typography>

                                                :

                                                req.respostasPossiveis.map(opt => (
                                                    <LoadingButton
                                                        key={opt.resposta}
                                                        onClick={handleResposta(opt.resposta)}
                                                        loading={isLoading}
                                                    >{opt.resposta}
                                                    </LoadingButton>
                                                ))


                                        }
                                    </CardContent>
                                </Card>
                            </Slide>

                        )
                    })}


                {!!data?.reqsRespondidas?.length && <div>
                    <Accordion sx={{ backgroundColor: 'transparent !important' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            Histórico
                        </AccordionSummary>

                        <AccordionDetails>
                            <Stack gap={2} sx={{ width: '100%' }}>
                                {data.reqsRespondidas.sort((a, b) => b.requisicaoCapa.dtRequisicao > a.requisicaoCapa.dtRequisicao ? 1 : -1).map((req, i) => {
                                    const capa = req.requisicaoCapa

                                    return (
                                        <Card id={i} key={capa.fluxo + capa.numero} >
                                            <CardContent sx={{ pt: '12px' }}>

                                                <Accordion sx={{ mb: '1em', bgcolor: 'transparent' }}  >
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 0, m: 0, ':first-of-type': { m: 0 } }} >
                                                        <CardHeader
                                                            title={rename(capa.fluxo)}
                                                            subheader={<Typography variant='body2' color={'GrayText'} sx={{ whiteSpace: 'pre-line' }}>{`${mdUp ? `Aberto por` : ''} ${capa.requisitante} ${mdUp ? 'em' : '\n'} ${format(new Date(capa.dtRequisicao), 'dd/MM/yyyy HH:mm')}`}</Typography>}
                                                            sx={{ p: 0 }}
                                                        />
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Stack gap={1}>

                                                            {req.dados.map(campo => (
                                                                <TextField key={campo.descricao} label={campo.descricao} value={campo.valor} size='small' InputProps={{
                                                                    readOnly: true,
                                                                }} />
                                                            ))}
                                                        </Stack>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <LoadingButton disabled>
                                                    {req.requisicaoCapa.status}
                                                </LoadingButton>

                                            </CardContent>
                                        </Card>

                                    )
                                })}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </div>}
            </Stack >

    );

}
