import { Stack } from '@mui/material';
import AvatarUploader from '../components/AvatarUploader';
import TrocaSenhaLogado from '../sections/auth/login/TrocaSenhaLogado';

export default function PerfilPage() {

    return <Stack direction={'row'} gap={2} >
        <AvatarUploader />
        <TrocaSenhaLogado />
    </Stack >
}