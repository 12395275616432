import { DatePicker } from '@mui/x-date-pickers';
import { Grid, Container, Stack, Typography, TextField, MenuItem, Box, Card, CardContent, CardHeader, CardActions, Button, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { useState } from 'react';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('1° Período', "13/11/2022", 16, "28/11/2022"),
    createData('2° Período', "13/11/2022", 16, "28/11/2022"),
    createData('3° Período', "13/11/2022", 16, "28/11/2022"),
];

export default function NovaSolicitacao() {
    const [value, setValue] = useState()
    return <Card variant="outlined" sx={{ maxWidth: '70%' }}>
        <CardHeader
            title="Nova solicitação"
            subheader="Período concessivo de 13/11/2022 a 12/11/2023"
        />
        <CardContent>
            {/* 
            <Stack direction='row' alignItems="center" mb={3} gap={2} >

                <TextField label="1° Período" value="13/11/2022" fullWidth />

            </Stack> */}
            <Stack spacing={3}>

                <Table aria-label="Períodos">
                    <TableHead>
                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell align="right">Início</TableCell>
                            <TableCell align="right">Dias</TableCell>
                            <TableCell align="right">Fim</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">

                                    <DatePicker
                                        label="Início"
                                        value={value}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </TableCell>
                                <TableCell align="right" width='100px'>
                                    <TextField
                                        label="Dias"
                                        type={'number'}
                                    />
                                </TableCell>
                                <TableCell align="right">{row.carbs}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <TextField
                    id="outlined-multiline-static"
                    label="Justificativa (opcional)"
                    multiline
                    rows={4}
                    fullWidth
                />

            </Stack>

        </CardContent>
        <CardActions>
            <Button>ENVIAR</Button>
        </CardActions>
    </Card>
}