import { UploadFile } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Card, CardActions, CardContent, Slider, Stack, Typography } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone'
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';
import account from '../_mock/account';

export default function AvatarUploader() {

    const { setUser, user } = useAuth()

    const canvas = useRef(null)

    const [image, setImage] = useState(user.pathFoto || account.photoURL)
    const [zoom, setZoom] = useState(1.2)
    const [changed, setChanged] = useState(false)

    const onDrop = useCallback(files => setImage(files[0]), [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true })
    const { ref: inputRef } = getInputProps()

    const axiosPrivate = useAxiosPrivate();


    const uploadImage = async () => {
        try {
            const base64 = canvas.current.getImageScaledToCanvas().toDataURL()
            setUser(prev => ({ ...prev, pathFoto: base64 }))
            setChanged(false)

            const [mimeType, arquivo] = base64.slice(5).split('base64,')

            await axiosPrivate.post(`/DadosCadastrais/alterar-foto-perfil/${user.codCliente}`, {
                arquivo, mimeType, nome: user.codUsuario
            });

        } catch (err) {
            console.error(err)
        }
    }

    return (
        <Card sx={{ maxWidth: 500, margin: { xs: 2, md: 0 }, width: { xs: '100%', md: 'unset' } }} {...getRootProps()}>
            <Stack sx={{ backdropFilter: 'brightness(0.7) blur(3px)', width: '100%', height: '100%', position: 'absolute', zIndex: isDragActive ? 1 : -1, alignItems: 'center', justifyContent: 'center', color: 'white', fontSize: 24, transition: '500ms', opacity: isDragActive ? 1 : 0 }}>
                <UploadFile fontSize='large' /> Solte o arquivo aqui!
            </Stack>

            <CardContent>
                <Stack gap={2}>

                    <Typography variant='h4'>Altere seu avatar</Typography>

                    <Stack>
                        <Button
                            variant='contained'
                            startIcon={<UploadFile />}
                            onClick={() => inputRef.current.click()}
                            fullWidth
                        >
                            Selecione uma foto
                        </Button>

                        <input {...getInputProps({
                            type: 'file',
                            accept: 'image/*',
                            style: {
                                width: 0,
                                zIndex: -1,
                                opacity: 0,
                            }
                        })}
                        />

                    </Stack>

                    <AvatarEditor
                        ref={canvas}
                        image={image}
                        width={200}
                        height={200}
                        border={20}
                        color={[255, 255, 255, 0.8]} // RGBA
                        scale={zoom}
                        rotate={0}
                        borderRadius={100}
                        onImageChange={() => setChanged(true)}
                        style={{ alignSelf: 'center' }}
                    />

                    <Slider value={zoom} onChange={(e, v) => setZoom(v)} min={1} max={10} step={.01} />

                </Stack>
            </CardContent>

            {changed &&
                <CardActions>
                    <LoadingButton onClick={uploadImage}>Salvar</LoadingButton>
                </CardActions>
            }

        </Card>



    )
}