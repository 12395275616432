import { Button } from '@mui/material'
import usePWA from 'react-pwa-install-prompt'

export default function InstallPWA() {
    const { isStandalone, isInstallPromptSupported, promptInstall } = usePWA()


    const onClickInstall = async () => {
        const didInstall = await promptInstall()
        if (didInstall) {
            // User accepted PWA install
        }
    }

    return (isInstallPromptSupported && !isStandalone)
        ? <Button onClick={onClickInstall}> Instalar App</Button>
        : null
}