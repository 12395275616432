import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Box, Alert, Zoom, FormControlLabel, Typography, useTheme, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { trocarSenha } from '../../../api/auth';
import LinearDeterminate from '../../../components/LinearDeterminate';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

// ----------------------------------------------------------------------



export default function EsqueceuSenha({ voltar }) {
  const theme = useTheme();

  const navigate = useNavigate();
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const axiosPrivate = useAxiosPrivate()

  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    setIsLoading(true)
    setLoginError("")

    try {

      const res = await axiosPrivate.put(`/forgot-password/${data.get('cpf')}`)

      if (res) {

        setSuccess(true)
        setTimeout(() => navigate(from, { replace: true }), 3000)
      }


    } catch (err) {
      console.log(err.message)
      setLoginError(err.message)

    }
    setIsLoading(false)

  };


  return success ?
    <Stack>
      <Typography variant='h4' color={theme.palette.primary.main} style={{ marginTop: '2em' }}>
        Uma senha temporária foi enviada para o seu e-mail
      </Typography>
      <LinearDeterminate duration={3000} />
    </Stack>

    : (
      <Box component="form" onSubmit={handleSubmit}>
        <Stack spacing={3}>

          <TextField
            required
            name="cpf"
            label="CPF"
            type={'number'}
            error={!!loginError}
          />


          <Zoom direction="up" in={!!loginError} mountOnEnter unmountOnExit>
            <Alert variant='filled' severity='error'>{loginError}</Alert>
          </Zoom>

          <Stack direction={'row'} gap={5}>
            <Button onClick={() => voltar()}>Cancelar</Button>

            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
              Resetar senha
            </LoadingButton>
          </Stack>



        </Stack>


      </Box>
    );
}
