// import { fluxos } from "../_mock/fluxos"

export function formatFluxos(fluxos) {
    return fluxos.map(fluxo => ({
        id: fluxo.capa.idFluxo,
        nome: fluxo.capa.descricao,
        campos: fluxo.capaDados
            .filter(v => v.ativo)
            .sort((a, b) => a.ordem - b.ordem)
            .map(c => ({
                key: c.idDado,
                name: c.descricao,
                label: c.observacao,
                gridWidth: Math.max(c.tamanhoCSS || 4, Math.round((c.observacao.length + 4) / 10)),
                type: c.tipo.split('(')[0],
                order: c.ordem,
                required: c.obrigatorio === 1,
                displayNone: c.visivel === 0,
                readOnly: c.soLeitura === 1
            })),
        req: {
            capa: {
                codCliente: fluxo.capa.codCliente,
                idFluxo: fluxo.capa.idFluxo
            },
            dados: fluxo.capaDados.map(c => ({
                codCliente: c.codCliente,
                idFluxo: c.idFluxo,
                idDado: c.idDado,
                valor: c.tipo.split('(')[0]
            }))
        },

    }))
        .map(applyManual)

}

function applyManual(fluxo) {
    switch (fluxo.nome) {
        case 'Fluxo de requisições de Férias':
            return {
                ...fluxo,
                nome: 'Férias',
                campos: fluxo.campos.map(c => ({ ...c, ...overrides[fluxo.nome][c.name] }))
            }

        case 'Fluxo de requisições de Alteração de Dados Cadastrais':
            return {
                ...fluxo,
                nome: 'Alteração de dados cadastrais',
                campos: fluxo.campos.map(c => ({ ...c, ...overrides[fluxo.nome][c.name] }))
            }

        default:
            return fluxo
    }
}

const overrides = {
    'Fluxo de requisições de Férias': {
        chapa: {
            displayNone: true
        },
        codcoligada: {
            displayNone: true
        },
        datainicio: {
            label: 'Início das férias',
            required: true
        },
        datafim: {
            label: 'Final das férias',
            required: true
        },
        nrodiasferias: {
            required: true
        },
        paga1aparc13o: {
            type: 'boolean'
        },
        fimperaquis: {
            displayNone: true
        }
    },

    'Fluxo de requisições de Alteração de Dados Cadastrais': {
        nome: {
            gridWidth: 12
        },
        chapa: {
            displayNone: true
        },
        codcoligada: {
            displayNone: true
        }
    }
}