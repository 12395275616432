import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { format } from 'date-fns';

// @mui
import { Card, Table, Stack, Paper, Avatar, Button, Popover, Checkbox, TableRow, MenuItem, TableBody, TableCell, Container, Typography, IconButton, TableContainer, TablePagination, CircularProgress, TableHead, Box } from '@mui/material';

import useAuth from '../hooks/useAuth';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useResponsive from '../hooks/useResponsive';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import { capitalCase } from '../utils/capitalCase';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'nome', label: 'Nome', alignRight: false },
  { id: 'chapa', label: 'Chapa', alignRight: false },
  { id: 'funcao', label: 'Função', alignRight: false },
  { id: 'secao', label: 'Seção', alignRight: false },
  { id: 'dataAdmissao', label: 'Admissão', alignRight: false },
  { id: 'codSituacao', label: 'Situação', alignRight: false },
  { id: '' },
];


// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.nome.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function EquipePage() {
  const { user } = useAuth()
  const { codCliente, codColigada, chapa } = user

  const [equipe, setEquipe] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(false)

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const mdUp = useResponsive('up', 'md')

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getData = async () => {
      try {
        setIsPageLoading(true)

        const res = await axiosPrivate.get(`/Equipes/${codCliente}?codColigada=${codColigada}&chapa=${chapa}`, {
          signal: controller.signal
        });

        if (isMounted) {
          console.log('equipes', res)
          setEquipe(res.data
            // .filter(pessoa => pessoa.codSituacao === 'A' && pessoa.funcao !== "PRESTADOR DE SERVICO")
            .sort((a, b) => a.nome < b.nome ? 1 : -1)
            .sort((a, b) => a.codSituacao > b.codSituacao ? 1 : -1)
          )
        }


        setIsPageLoading(false)


      } catch (err) {
        setIsPageLoading(false)
        if (err?.response?.status === 400 && err?.response?.data !== 'Parâmetros inválidos!') navigate('/login', { state: { from: location }, replace: true });
      }
    }

    getData()



    return () => {
      isMounted = false;
      controller.abort();
    }

  }, [])

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - equipe.length) : 0;

  const filteredUsers = applySortFilter(equipe, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Equipe </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Equipe
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          {mdUp ?
            <>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={equipe.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {isPageLoading ?
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper sx={{ textAlign: 'center' }}>
                              <CircularProgress />
                            </Paper>
                          </TableCell>
                        </TableRow>

                        :

                        filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((usuario) => {
                          const { chapa, nome, funcao, codSituacao, secao, pathFoto, dataAdmissao, codColigada } = usuario;

                          return (
                            <TableRow hover key={chapa} tabIndex={-1}>

                              <TableCell component="th" scope="row" padding="normal">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Avatar alt={nome} src={pathFoto} />
                                  <Typography variant="subtitle2" noWrap>
                                    {capitalCase(nome)}
                                  </Typography>
                                </Stack>
                              </TableCell>

                              <TableCell align="left">{chapa}</TableCell>
                              <TableCell align="left">{capitalCase(funcao)}</TableCell>
                              <TableCell align="left">{capitalCase(secao)}</TableCell>
                              <TableCell align="left">{format(new Date(dataAdmissao), 'dd/MM/yyy')}</TableCell>


                              <TableCell align="left">
                                <Label color={(codSituacao === 'A' && 'success') || 'error'}>{codSituacao}</Label>
                              </TableCell>

                              <TableCell align="right">
                                <IconButton size="large" color="inherit" onClick={handleOpenMenu} id={`codColigada=${codColigada}&chapa=${chapa}`}>
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      }


                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Não encontrado
                              </Typography>

                              <Typography variant="body2">
                                Nenhum resultado para &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Veja se não digitou errado.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={equipe.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage='Empregados por página'
              />

            </>

            :

            isPageLoading ?
              <Paper sx={{ textAlign: 'center', my: 5 }} >
                <CircularProgress />
              </Paper>

              :
              isNotFound ? (

                <Paper
                  sx={{
                    textAlign: 'center',
                    mb: 3
                  }}
                >
                  <Typography variant="h6" paragraph>
                    Não encontrado
                  </Typography>

                  <Typography variant="body2">
                    Nenhum resultado para &nbsp;
                    <strong>&quot;{filterName}&quot;</strong>.
                    <br /> Veja se não digitou errado.
                  </Typography>
                </Paper>

              )

                : filteredUsers.map((usuario) => {
                  const { chapa, nome, funcao, codSituacao, secao, pathFoto, codColigada } = usuario;

                  return <TableContainer component={Paper} sx={{ mb: '2em' }} key={chapa}>
                    <Box mb={1}>
                      <Typography fontWeight={'bold'} textAlign='center'>{capitalCase(nome)}</Typography>
                    </Box>

                    <Table size='small'>
                      <TableBody>
                        {TABLE_HEAD.slice(1).map(v => v.id ?
                          <TableRow key={chapa + v.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell><strong>{v.label}</strong></TableCell>
                            {
                              v.id === 'codSituacao' ?
                                <TableCell>
                                  <Label color={(usuario[v.id] === 'A' && 'success') || 'error'}>{usuario[v.id]}</Label>
                                </TableCell>
                                :
                                v.id === 'dataAdmissao' ?
                                  <TableCell align="left">{format(new Date(usuario[v.id]), 'dd/MM/yyy')}</TableCell>
                                  :
                                  <TableCell>{capitalCase(usuario[v.id])}</TableCell>
                            }
                          </TableRow>

                          :
                          <TableRow

                            key={chapa + v.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell colSpan={2}>
                              <Stack>
                                <Button onClick={() => navigate(`/app/dados-cadastrais?codColigada=${codColigada}&chapa=${chapa}`)}>Dados cadastrais</Button>
                                <Button onClick={() => navigate(`/app/nova-requisicao?codColigada=${codColigada}&chapa=${chapa}#Férias`)}>Marcar férias</Button>
                              </Stack>

                            </TableCell>
                          </TableRow>

                        )}


                      </TableBody>




                    </Table>
                  </TableContainer>
                })


          }


        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => navigate(`/app/dados-cadastrais?${open.id}`)}>
          Dados cadastrais
        </MenuItem>
        <MenuItem onClick={() => navigate(`/app/nova-requisicao?${open.id}#Férias`)}>
          Marcar férias
        </MenuItem>

      </Popover>
    </>
  );
}
