import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Stack, Typography, Button } from '@mui/material';

import MinhasRequisicoes from '../components/MinhasRequisicoes';
import RequisicoesParaAprovacao from '../components/RequisicoesParaAprovacao';



export default function RequisicoesPage() {

  return (<>
    <Helmet><title>Requisições</title></Helmet>

    <Container >
      <Button component={Link} to='/app/nova-requisicao' variant='contained' sx={{ mb: '2em' }} >Nova requisição</Button>

      <Stack direction={{ xs: 'column-reverse', md: 'row' }} gap={5} >

        <Stack gap={1} sx={{ width: '100%' }}>
          <Typography variant='h4'>Minhas requisições</Typography>
          <MinhasRequisicoes />
        </Stack>

        <Stack gap={1} sx={{ width: '100%' }}>
          <Typography variant='h4'>Requisições para aprovação</Typography>
          <RequisicoesParaAprovacao />
        </Stack>

      </Stack>
    </Container>
  </>
  )
}
