import { Checkbox, FormControlLabel, TextField } from "@mui/material"
import { forwardRef } from "react"

export default forwardRef(function ReqField({ type = "", minMax = {}, ...others }, ref) {

    switch (type) {
        case "int":
            return <TextField type={'number'} inputProps={{ step: 1, min: minMax?.[others?.name]?.min || 0, max: minMax?.[others?.name]?.max }} {...others} />

        case "nvarchar":
            return <TextField multiline minRows={4} {...others} />

        case "varbinary":
            delete others.value
            return <TextField inputRef={ref} type={'file'} InputLabelProps={{ shrink: true }} {...others} />

        case "datetime":
            return <TextField type={'date'} InputLabelProps={{ shrink: true }} inputProps={{ min: minMax?.[others?.name]?.min, max: minMax?.[others?.name]?.max }} {...others} />
        // return <DatePicker renderInput={(params) => <TextField {...params} />} {...others} />

        case "month":
            return <TextField type={'month'} InputLabelProps={{ shrink: true }} {...others} />

        case "boolean":
            delete others.fullWidth
            delete others.helperText
            return <FormControlLabel control={
                <Checkbox checked={others.value || false} {...others} />
            }
                label={others.label}
                sx={{ height: '100%' }}
            />

        default:
            return <TextField {...others} />
    }
})