import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Box, Alert, Zoom, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { login } from '../../../api/auth';
import useAuth from '../../../hooks/useAuth';
import TrocaSenha from './TrocaSenha';
import EsqueceuSenha from './EsqueceuSenha';

// ----------------------------------------------------------------------



export default function LoginForm() {

  const { setAuth, setUser, persist, setPersist } = useAuth()

  const navigate = useNavigate();
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [alterarSenha, setAlterarSenha] = useState(false);
  const [esqueceuSenha, setEsqueceuSenha] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    setIsLoading(true)
    setLoginError("")

    try {

      const res = await login({
        cpf: data.get('cpf'),
        password: data.get('password'),
      })

      if (res) {

        setAuth(res)
        setUser({ ...res.usuariosApp[0], ...res.usuariosApp[0].chavesFuncAtivo[0] })

        if (!res.usuariosApp[0].alteraSenha)
          navigate(from, { replace: true })
        else setAlterarSenha(data)
      }


    } catch (err) {
      console.log(err.message)
      setLoginError(err.message)

    }
    setIsLoading(false)

  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist])

  return alterarSenha ? <TrocaSenha prev={alterarSenha} /> :
    esqueceuSenha ? <EsqueceuSenha voltar={() => setEsqueceuSenha(false)} /> : (
      <Box component="form" onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            required
            name="cpf"
            label="CPF"
            type={'number'}
            error={!!loginError}
          />

          <TextField
            required
            name="password"
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            error={!!loginError}

            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Zoom direction="up" in={!!loginError} mountOnEnter unmountOnExit>
            <Alert variant='filled' severity='error'>{loginError}</Alert>
          </Zoom>
        </Stack>


        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel control={
            <Checkbox
              name="remember"
              onChange={() => setPersist(prev => !prev)}
              checked={persist}
            />} label="Lembrar de mim" />
          <Link variant="subtitle2" underline="hover" sx={{ cursor: "pointer" }} onClick={() => setEsqueceuSenha(true)} >
            Esqueceu a senha?
          </Link>
        </Stack>


        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
          Entrar
        </LoadingButton>
      </Box>
    );
}
