import { format, parseISO, parseJSON } from 'date-fns'
import { options } from 'numeral'
import { dadosCadastrais } from "../_mock/dadosCadastrais"

export function formatDadosCadastrais(dadosCadastrais) {
    console.log('formatDados')
    return dadosCadastrais
        .sort((a, b) => (a.dadosFuncionais.codsituacao > b.dadosFuncionais.codsituacao ? 1 : -1))
        .map(matricula => ({
            matricula: `${matricula.dadosChave.codColigada} - ${matricula.dadosChave.chapa}${matricula.dadosFuncionais.codsituacao === "A" ? " (Ativo)" : ""}`,
            secoes: Object.entries(aggregate(matricula)).map(([key, value]) =>
                Array.isArray(value) ?
                    {
                        titulo: applyLabels(key),
                        tipo: 'lista',
                        lista: value.map(val => applyLayout({
                            titulo: applyLabels(key),
                            linhas: Object.entries(val).filter(([, v]) => v).map(([k, v]) => ({
                                titulo: applyLabels(k),
                                valor: applyFormat(k, v)
                            })
                            )
                        }))
                    }

                    : applyLayout({
                        titulo: applyLabels(key),
                        linhas: Object.entries(value).filter(([, v]) => v).map(([key, value]) => ({
                            titulo: applyLabels(key),
                            valor: applyFormat(key, value)
                        }))
                    })

            )

        })
        )
}



function applyLayout(secao) {
    const layout = {
        'Dados chave': [
            ['Código do cliente', 'Código pessoal'],
            ['Código da coligada', 'Chapa']
        ],
        'Dados pessoais': [
            ['Nome', 'Data de nascimento']
        ],
        'Documentação': [
            ['CPF', 'PIS'],
            ['CTPS', 'Série', 'UF']
        ],
        'Contatos': [
            ['Telefone 1', 'Telefone 2', 'Telefone 3'],
            ['E-mail', 'E-mail pessoal']
        ],
        'Dados funcionais': [
            ['Data de admissão', 'Data de demissão'],
            ['Código da função', 'Função'],
            ['Código da seção', 'Seção'],
            ['Banco', 'Agência', 'Conta corrente', 'Salário']
        ],
        'Dependentes': [
            ['Nome'],
            ['Parentesco', 'Sexo'],
            ['Data de nascimento', 'CPF']
        ],
        'Histórico de afastamentos': [
            ['Início', 'Fim'],
            ['Tipo', 'Motivo']
        ],
        'Histórico de salários': [
            ['Data da mudança', 'Motivo'],
            ['Novo salário']
        ],
        'Histórico de funções': [
            ['Data da mudança', 'Motivo'],
            ['Função']
        ],
        'Histórico de seções': [
            ['Data da mudança', 'Motivo'],
            ['Seção']
        ],
        'Histórico de férias': [
            ['Início das férias', 'Final das férias', 'Quantidade de dias de férias'],
            ['Data do pagamento', 'Data do Aviso', 'Quantidade de dias de abono'],
            ['Foi paga a primeira parcela do 13º salário nas férias?', 'Situação']
        ]




    }

    if (layout[secao.titulo]) {
        const linhas = layout[secao.titulo].map(
            linha => linha.map(
                t => secao.linhas.find(({ titulo }) => titulo === t)
            ))

        secao.linhas = [...linhas,
        ...secao.linhas.filter(({ titulo }) =>
            !layout[secao.titulo].reduce((t, v) => [...t, ...v], []).includes(titulo))
            .map(v => [v])
        ]

    } else secao.linhas = secao.linhas.map(v => [v])


    return secao

}

function applyLabels(titulo) {
    const labels = {

        codCliente: 'Código do cliente',
        codPessoa: 'Código pessoal',
        codColigada: 'Código da coligada',
        chapa: 'Chapa',

        nome: 'Nome',
        dtnascimento: 'Data de nascimento',

        cpf: 'CPF',
        pispasep: 'PIS',
        carteiratrab: 'CTPS',
        seriecarttrab: 'Série',
        ufcarttrab: 'UF',

        telefonE1: 'Telefone 1',
        telefonE2: 'Telefone 2',
        telefonE3: 'Telefone 3',
        email: 'E-mail',
        emailpessoal: 'E-mail pessoal',

        dataadmissao: 'Data de admissão',
        codsecao: 'Código da seção',
        secao: 'Seção',
        codfuncao: 'Código da função',
        funcao: 'Função',
        codbancopagto: 'Banco',
        codagenciapagto: 'Agência',
        contapagamento: 'Conta corrente',
        salario: 'Salário',
        codsituacao: 'Situação',
        datademissao: 'Data de demissão',
        // tipodemissao: 'Tipo da demissão',

        nrodepend: 'Número do dependente',
        sexo: 'Sexo',
        grauparentesco: 'Código do parentesco',
        descricaoparentesco: 'Parentesco',

        afastamento_inicio: 'Data de Início',
        afastamento_fim: 'Data Fim',
        afatamento_tipo: 'Tipo de Afastamento',

        salario_data: 'Data da Mudança',
        salario_motivo: 'Motivo',
        salario_novo: 'Novo salário',
        salario_reajuste: 'Percentual de reajuste',

        funcao_data: 'Data da Mudança',
        funcao_motivo: 'Motivo',
        funcao_nova: 'Nova função',

        dadosChave: 'Dados chave',
        dadosPessoais: 'Dados pessoais',
        dadosDocumentacao: 'Documentação',
        dadosContatos: 'Contatos',
        dadosFuncionais: 'Dados funcionais',
        dadosDependentes: 'Dependentes',

        histGozoFerias: 'Histórico de férias',
        dataPagto: 'Data do pagamento',
        dataInicio: 'Início das férias',
        dataFim: 'Final das férias',
        dataAviso: 'Data do Aviso',
        nroDiasAbono: 'Quantidade de dias de abono',
        paga1aParcela13: 'Foi paga a primeira parcela do 13º salário nas férias?',
        situacaoFerias: 'Situação',
        nroDiasFerias: 'Quantidade de dias de férias',

        histSalarios: 'Histórico de salários',
        dtmudanca: 'Data da mudança',
        descmotivo: 'Motivo',

        histAfastamentos: 'Histórico de afastamentos',
        dtinicio: 'Início',
        dtfinal: 'Fim',
        desctipo: 'Tipo',

        histFuncoes: 'Histórico de funções',

        histSecoes: 'Histórico de seções',

    }

    return labels[titulo] || titulo
}

function mascaraCPF(cpf) {
    let cpfMascarado = "";
    for (let i = 0; i < cpf.length; i++) {
        if (i === 3 || i === 6) cpfMascarado += ".";
        if (i === 9) cpfMascarado += "-";
        cpfMascarado += cpf[i];
    }
    return cpfMascarado;
}

function mascaraTelefone(telefone) {
    if (telefone.length === 9) return `${telefone.slice(0, 5)}-${telefone.slice(5)}`
    if (telefone.length === 8) return `${telefone.slice(0, 4)}-${telefone.slice(4)}`
    if (telefone.length > 12) return telefone

    let ddd = ''
    if (telefone[0] === '0') {
        ddd = telefone.slice(0, 3)
        telefone = telefone.slice(3)
    } else {
        ddd = telefone.slice(0, 2)
        telefone = telefone.slice(2)
    }

    if (telefone.length === 9) return `(${ddd}) ${telefone.slice(0, 5)}-${telefone.slice(5)}`
    return `(${ddd}) ${telefone.slice(0, 4)}-${telefone.slice(4)}`
}

function mascaraPispasep(v) {
    v = v.replace(/\D/g, "")
    v = v.replace(/^(\d{3})(\d)/, "$1.$2")
    v = v.replace(/^(\d{3})\.(\d{5})(\d)/, "$1.$2.$3")
    v = v.replace(/(\d{3})\.(\d{5})\.(\d{2})(\d)/, "$1.$2.$3-$4")
    return v
}

function applyFormat(k, v) {
    if (k === 'salario') return v.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    if (k === 'paga1aParcela13') return v === 1 ? 'Sim' : 'Não'
    if (typeof v !== 'string') return v

    if (k.includes('dt') || k.includes('data')) {
        const date = parseISO(v)
        if (date.toString() === 'Invalid Date') return v

        return format(date, 'dd/MM/yyyy')
    }

    if (k.startsWith('telef')) return mascaraTelefone(v)
    if (k === 'cpf') return mascaraCPF(v)
    if (k === 'pispasep') return mascaraPispasep(v)

    return v

}

function aggregate(obj) {
    if (obj.dadosPessoais && obj.dadosPessoais.rua) {
        const dp = obj.dadosPessoais
        const endereco = `${dp.rua || ''} - ${dp.numero || ''} ${dp.complemento || ''}, ${dp.bairro || ''}, ${dp.cidade || ''} - ${dp.estado || ''}, ${dp.cep || ''}`;

        ['rua', 'numero', 'complemento', 'bairro', 'cidade', 'estado', 'cep'].forEach(key => delete dp[key])

        dp['Endereço'] = endereco
    }

    return obj
}

