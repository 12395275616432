import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Card, CardActions, CardContent, Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Box, Alert, Zoom, FormControlLabel, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import LinearDeterminate from '../../../components/LinearDeterminate';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import axios from '../../../api/axios';

// ----------------------------------------------------------------------



export default function TrocaSenhaLogado() {
  const theme = useTheme();

  const navigate = useNavigate();
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const axiosPrivate = useAxiosPrivate()

  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    setIsLoading(true)
    setLoginError("")

    try {

      const res = await axiosPrivate.put(`/change-password`,
        Object.fromEntries(data.entries())
      )

      if (res) {
        setSuccess(true)
      }


    } catch (err) {
      console.log(err.message)
      setLoginError(err.message)

    }
    setIsLoading(false)

  };


  return <Card sx={{ maxWidth: 500, margin: { xs: 2, md: 0 }, width: { xs: '100%', md: '300px' } }} >

    <CardContent>
      <Stack gap={2}>

        <Typography variant='h4'>Altere sua senha</Typography>


        {success ?
          <Typography variant='h4' color={theme.palette.primary.main} style={{ marginTop: '2em' }}>
            Senha alterada <br />com sucesso =)
          </Typography>

          : (
            <Box component="form" onSubmit={handleSubmit}>
              <Stack spacing={3}>

                <TextField
                  required
                  name="cpf"
                  label="CPF"
                  type={'number'}
                  error={!!loginError}
                />

                <TextField
                  required
                  name="currentPassword"
                  label="Senha atual"
                  type={showPassword ? 'text' : 'password'}
                  error={!!loginError}

                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  required
                  name="newPassword"
                  label="Nova senha"
                  type={showPassword ? 'text' : 'password'}
                  error={!!loginError}

                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  required
                  name="confirmPassword"
                  label="Repita a nova senha"
                  type={showPassword ? 'text' : 'password'}
                  error={!!loginError}

                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Zoom direction="up" in={!!loginError} mountOnEnter unmountOnExit>
                  <Alert variant='filled' severity='error'>{loginError}</Alert>
                </Zoom>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
                  Alterar senha
                </LoadingButton>

              </Stack>


            </Box>
          )
        }


      </Stack>
    </CardContent>

  </Card>


}
