import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Box, Alert, Zoom, FormControlLabel, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { trocarSenha } from '../../../api/auth';
import LinearDeterminate from '../../../components/LinearDeterminate';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

// ----------------------------------------------------------------------



export default function TrocaSenha({ prev }) {
  const theme = useTheme();

  const navigate = useNavigate();
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const axiosPrivate = useAxiosPrivate()

  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    setIsLoading(true)
    setLoginError("")

    try {

      const res = await axiosPrivate.put(`/change-password`, {
        cpf: prev.get('cpf'),
        currentPassword: prev.get('password'),
        ...Object.fromEntries(data.entries())
      })

      if (res) {

        setSuccess(true)
        setTimeout(() => navigate(from, { replace: true }), 3000)
      }


    } catch (err) {
      console.log(err.message)
      setLoginError(err.message)

    }
    setIsLoading(false)

  };


  return success ?
    <Stack>
      <Typography variant='h4' color={theme.palette.primary.main} style={{ marginTop: '2em' }}>Senha alterada com sucesso =)</Typography>
      <LinearDeterminate duration={3000} />
    </Stack>

    : (
      <Box component="form" onSubmit={handleSubmit}>
        <Stack spacing={3}>

          {
            !prev.get('cpf') && <TextField
              required
              name="cpf"
              label="CPF"
              type={'number'}
              error={!!loginError}
            />
          }

          {


            !prev.get('password') && <TextField
              required
              name="currentPassword"
              label="Senha atual"
              type={showPassword ? 'text' : 'password'}
              error={!!loginError}

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />}

          <TextField
            required
            name="newPassword"
            label="Nova senha"
            type={showPassword ? 'text' : 'password'}
            error={!!loginError}

            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            required
            name="confirmPassword"
            label="Repita a nova senha"
            type={showPassword ? 'text' : 'password'}
            error={!!loginError}

            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Zoom direction="up" in={!!loginError} mountOnEnter unmountOnExit>
            <Alert variant='filled' severity='error'>{loginError}</Alert>
          </Zoom>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
            Alterar senha
          </LoadingButton>

        </Stack>


      </Box>
    );
}
