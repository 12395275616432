import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pl: { xs: 2, md: 3 } }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function VerticalTabs({ children }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{ flexGrow: 1, display: 'flex' }}
        >
            <Tabs
                orientation="vertical"
                selectionFollowsFocus
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs"
                sx={{ borderColor: 'divider', width: { xs: '170px', md: 'auto' } }}
            >
                {children.map((item, i) => (
                    <Tab key={`tab${item.key}`} label={item.key} sx={{ alignItems: 'end', textAlign: 'right', px: { xs: 1, md: 2 } }} {...a11yProps(i)} />
                ))}

            </Tabs>

            {children.map((item, i) => (
                <TabPanel style={{ width: '100%' }} key={`tabPanel${item.key}`} value={value} index={i}>
                    {item}
                </TabPanel>
            ))}

        </Box>
    );
}