import { axiosPrivate } from './axios'

export async function sendRequisicao(data) {

    try {
        const res = await axiosPrivate.post('/Requisicoes', data, { withCredentials: true })
        if (res.status === 200) {
            console.log(res.data)
            return res.data
        }
    } catch (err) {

        const erro = err.response?.data
        console.log(erro)

        if (erro === undefined) {
            throw new Error('Falha ao se conectar com o servidor')
        }

        throw new Error(JSON.stringify(erro))
        // return false
    }

    return false


}
